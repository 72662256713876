import {onFind} from "@elements/init-modules-in-scope";
import {addClass, hasClass} from "@elements/dom-utils";

export function init () {
    onFind('.wysiwyg table', function ($element) {
        let el = $element;
        let wrapper = document.createElement('div');

        //remove style tag from el
        el.removeAttribute('style');

        if(hasClass('table__technical-data', el)){
            addClass('table-responsive', wrapper);
        }else{
            addClass('table-responsive-lg', wrapper);
        }

        addClass('table-wrapper', wrapper);
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el)

    });
    onFind('table.cockpit-table', function ($element) {
        let el = $element;
        let wrapper = document.createElement('div');

        //remove style tag from el
        el.removeAttribute('style');

        addClass('table-responsive-xxl', wrapper);

        addClass('table-wrapper', wrapper);
        el.parentNode.insertBefore(wrapper, el);
        wrapper.appendChild(el)
    });
}